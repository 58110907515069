<template>
  <div style="display: flex; justify-content: space-between; align-items: flex-end">
    <div>
      <span class="title"> {{ title }} </span><br>
      <span class="subtitle"> {{ subTitle }} </span>
    </div>

    <div style="display: flex; justify-content: space-between; align-items: flex-end">

      <div @click="page = 0" class="tabButton">
        <span class="tabtitle"><i class="fas fa-hand-point-up" style="font-size: large"></i></span><br>
        <div :style="{backgroundColor: page == 1 ? '#00000000' : '#fff', height: '2px'}"></div>
      </div>


      <div @click="page = 1" v-if="badge > 0" class="tabButton">
        <el-badge :value="badge">
          <span class="tabtitle"><i class="fas fa-sliders-h" style="font-size: large"></i></span>
        </el-badge>
        <br>
        <div :style="{backgroundColor: page == 0 ? '#00000000' : '#fff', height: '2px'}"></div>
      </div>



    </div>


    <img class="icon" :src="'/assets/icons/' + icon + '.png'" alt="">

  </div>
</template>

<script>
  export default {
    name: "Header",

    props: {
      title: {
        type: String,
        default: ""
      },

      subTitle: {
        type: String,
        default: ""
      },

      icon: {
        type: String,
        default: ""
      },

      badge: {
        type: Number,
        default: 0
      },

      currentPage: {
        type: Number,
        default: 0
      }

    },



    computed: {
      page: {
        get: function () {
          return this.currentPage
        },

        set: function (newPage) {
          this.currentPage = newPage
          this.$emit("onChangePage", newPage)
        }
      }
    },

    watch: {
      page: function (val1, val2) {
        console.log("currentPage Changed", val1, val2)
      }
    },
  }
</script>

<style scoped>
  .icon {
    /* filter: invert(99%) sepia(99%) saturate(1%) hue-rotate(243deg) brightness(105%) contrast(100%); */
    color: #fff;
    width: 40px;
    height: 40px;
  }
  .title {
    font-size: small;
    font-weight: bold;
    color: #fff;
  }

  .subtitle {
    font-size: smaller;
    color: #fff;
  }

  .tabtitle {
    font-size: larger;
    color: #fff;
    margin-right: 10px;
    margin-left: 10px;
  }

  .tabButton {
    cursor: pointer;
  }
</style>