<template>
  <el-card style="min-height: 345px">
    <Header
      slot="header"
      :title="accessory.name"
      :subTitle="roomName"
      :icon="accessory.icon"
      :badge="actions.length"
      @onChangePage="onChangePage"
    ></Header>

    <div class="content-container">
      <div v-if="currentPage == 0">
        <el-tabs stretch v-model="tabValue" tab-position="bottom">
          <el-tab-pane name="brightness" label="Brightness">
            <el-row>
              <el-col :span="12" class="column-container">
                <div class="mode-control">
                  <div class="mode-button" :style="{color: accessory.powerOn ? '#00BFD7' : '#999999'}" @click="onSwitch(true)">
                    I
                  </div>
                  <div class="mode-button" :style="{color: !accessory.powerOn ? '#00BFD7' : '#999999'}" @click="onSwitch(false)">
                    O
                  </div>
                </div>
                <br />
                <!-- <div>{{ status }}</div> -->
              </el-col>
              <el-col :span="12" class="column-container">
                <el-slider
                  vertical
                  class="slider-container"
                  v-model="accessory.brightnessValue"
                  height="120px"
                  @change="onChangeBrightnessLevel"
                ></el-slider>
                <br />
                {{ $t("scene.brightness-level") }}
              </el-col>
            </el-row>
          </el-tab-pane>

          <el-tab-pane name="colorAbsoluteTemperature" label="Color Absolute Temperature">
            <div class="column-container">
              <el-slider
                style="width: 80%; margin-bottom: 20px"
                :min="accessory.minColorAbsoluteTemperature"
                :max="accessory.maxColorAbsoluteTemperature"
                v-model="accessory.colorAbsoluteTemperature"
                @change="onChangeWhite"
              ></el-slider>
            </div>
          </el-tab-pane>
        </el-tabs>
      </div>

      <ActionList
        v-else
        :actions="actions"
        :currentPage="currentPage"
        @onChange="onChangeActions"
      />
    </div>
  </el-card>
</template>

<script>
import { accessoryMixin } from "./accessoryMixin";
import {
  Material,
  Compact,
  Swatches,
  Slider,
  Sketch,
  Chrome,
  Photoshop,
} from "vue-color";

export default {
  name: "RGBWDimmer",

  mixins: [accessoryMixin],

  components: {
    "material-picker": Material,
    "compact-picker": Compact,
    "swatches-picker": Swatches,
    "slider-picker": Slider,
    "sketch-picker": Sketch,
    "chrome-picker": Chrome,
    "photoshop-picker": Photoshop,
  },

  data: function() {
    return {
      status: "",
      tabValue: "brightness",
    };
  },

  watch: {
  },

  methods: {
    onSwitch: function(value) {
      this.accessory.powerOn = value;
      this.accessory.brightnessValue = this.accessory.powerOn ? 100 : 0;

      this.createAction("setOn", this.accessory.powerOn);
      this.setStatus();
    },

    onChangeBrightnessLevel: function() {
      if (this.accessory.brightnessValue > 0) this.accessory.powerOn = true;
      else this.accessory.powerOn = false;

      this.createAction("setTunableBrightness", this.accessory.brightnessValue);
      this.setStatus();
    },

    onChangeWhite: function() {
      this.createAction("setTunableColorTemperature", this.accessory.colorAbsoluteTemperature);
    },

    setStatus: function() {
      this.status = this.accessory.powerOn
        ? this.$t("scene.on")
        : this.$t("scene.off");
    },
  },

  created() {
    this.accessory = {...this.sceneActionData.accessory}
    this.actions = [...this.sceneActionData.actions]
    // console.log('ACCESSORY', this.accessory)
    if (this.accessory.actionMode) this.accessory.actionMode = "";
  },
};
</script>

<style scoped>
.column-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 180px;
}

.slider-container {
}
.mode-control {
  border: 1px solid #cccccc;
  border-radius: 8px;
  padding-top: 5px;
  padding-bottom: 5px;
}
.mode-button {
  cursor: pointer;
  padding: 8px;
  margin: 2px;
  font-size: 20px;
  text-align: center;
}

.mode-button:hover {
  color: dodgerblue;
}

.content-container {
  height: 230px;
}    
.el-card {
      border: 1px solid #ebeef5;
      border-radius: 10px;
  }
</style>
