var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "automation" },
    [
      _c(
        "el-row",
        { staticStyle: { "margin-left": "20px", "margin-right": "20px" } },
        [
          _c(
            "el-col",
            { attrs: { span: 24 } },
            [
              _c(
                "el-button",
                {
                  staticStyle: { float: "right" },
                  attrs: { type: "text" },
                  nativeOn: {
                    click: function($event) {
                      $event.preventDefault()
                      return _vm.nextStep($event)
                    }
                  }
                },
                [
                  _vm._v(_vm._s(_vm.$t("scene.next"))),
                  _c("i", { staticClass: "el-icon-d-arrow-right" })
                ]
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "el-form",
        {
          ref: "form",
          staticClass: "form-container",
          attrs: {
            model: _vm.sceneData,
            "label-position": "right",
            "label-width": "120px"
          }
        },
        [
          _c(
            "el-form-item",
            {
              attrs: {
                prop: "name",
                label: _vm.$t("scene.scene"),
                rules: _vm.rules.name
              }
            },
            [
              _c("el-input", {
                staticStyle: { width: "200px" },
                attrs: { type: "text", placeholder: _vm.$t("scene.type-name") },
                model: {
                  value: _vm.sceneData.name,
                  callback: function($$v) {
                    _vm.$set(_vm.sceneData, "name", $$v)
                  },
                  expression: "sceneData.name"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: _vm.$t("scene.icon") } },
            [
              _c(
                "el-col",
                { attrs: { span: 7 } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: {
                        filterable: "",
                        placeholder: _vm.$t("scene.select-icon")
                      },
                      model: {
                        value: _vm.icon,
                        callback: function($$v) {
                          _vm.icon = $$v
                        },
                        expression: "icon"
                      }
                    },
                    _vm._l(_vm.icons, function(icon, index) {
                      return _c(
                        "el-option",
                        {
                          key: index,
                          attrs: { label: icon.title, value: icon.value }
                        },
                        [
                          _c("span", { staticClass: "icon-select-layout" }, [
                            _c("img", {
                              staticClass: "icon-select",
                              attrs: {
                                src: "/assets/icons/" + icon.value + ".png"
                              },
                              on: { error: _vm.onImageError }
                            }),
                            _vm._v(_vm._s(icon.title))
                          ])
                        ]
                      )
                    }),
                    1
                  )
                ],
                1
              ),
              _c("el-col", { attrs: { span: 17 } }, [
                _c("span", [
                  _c("img", {
                    staticClass: "icon-contact",
                    attrs: { src: "/assets/icons/" + _vm.icon + ".png" },
                    on: { error: _vm.onImageError }
                  })
                ])
              ])
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              attrs: {
                prop: "roomId",
                label: _vm.$t("scene.room"),
                rules: _vm.rules.room
              }
            },
            [
              _c(
                "el-select",
                {
                  staticStyle: { width: "150px" },
                  attrs: {
                    placeholder: _vm.$t("scene.select-room"),
                    filterable: ""
                  },
                  model: {
                    value: _vm.sceneData.roomId,
                    callback: function($$v) {
                      _vm.$set(_vm.sceneData, "roomId", $$v)
                    },
                    expression: "sceneData.roomId"
                  }
                },
                _vm._l(_vm.$store.getters.rooms, function(room) {
                  return _c("el-option", {
                    key: room.id,
                    attrs: { label: room.name, value: room.id }
                  })
                }),
                1
              )
            ],
            1
          ),
          _c(
            "el-row",
            [
              _c(
                "el-col",
                { attrs: { span: 6 } },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        prop: "favorite",
                        label: _vm.$t("scene.favorite")
                      }
                    },
                    [
                      _c("el-switch", {
                        model: {
                          value: _vm.sceneData.favorite,
                          callback: function($$v) {
                            _vm.$set(_vm.sceneData, "favorite", $$v)
                          },
                          expression: "sceneData.favorite"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 6 } },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        prop: "invisible",
                        label: _vm.$t("scene.invisible")
                      }
                    },
                    [
                      _c("el-switch", {
                        model: {
                          value: _vm.sceneData.invisible,
                          callback: function($$v) {
                            _vm.$set(_vm.sceneData, "invisible", $$v)
                          },
                          expression: "sceneData.invisible"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 6 } },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        prop: "modifiable",
                        label: _vm.$t("scene.modifiable")
                      }
                    },
                    [
                      _c("el-switch", {
                        model: {
                          value: _vm.sceneData.modifiable,
                          callback: function($$v) {
                            _vm.$set(_vm.sceneData, "modifiable", $$v)
                          },
                          expression: "sceneData.modifiable"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }