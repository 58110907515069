<template>
  <div class="automation">
    <el-row style="margin-left: 20px; margin-right: 20px">
      <el-col :span="24">
        <el-button style="float: right" type="text" :loading="loading" @click.native.prevent="onSaveScene">
          {{ nextTitle }}
        </el-button>
      </el-col>
    </el-row>

    <el-form ref="form" :model="sceneData" class="form-container" label-position="right" label-width="120px" >

      <el-form-item prop="name" :label="$t('scene.scene')" :rules="rules.name">
        <el-input v-model="sceneData.name" type="text" :placeholder="$t('scene.type-name')" style="width: 200px">

        </el-input>
      </el-form-item>


      <el-form-item :label="$t('scene.icon')">

        <el-col :span="7">
          <el-select v-model="sceneData.icon" filterable :placeholder="$t('scene.select-icon')">
            <el-option v-for="(icon, index) in icons" :key="index" :label="icon.title" :value="icon.value">
              <span class="icon-select-layout"><img @error="onImageError" :src="'/assets/icons/' + icon.value + '.png'" class="icon-select">{{icon.title}}</span>
            </el-option>
          </el-select>
        </el-col>
        <el-col :span="17">
              <span>
                <img @error="onImageError" :src="'/assets/icons/' + sceneData.icon + '.png'" class="icon-contact">
              </span>
        </el-col>

      </el-form-item>


      <el-form-item prop="roomId" :label="$t('scene.room')" :rules="rules.room">
        <el-select v-model="sceneData.roomId" :placeholder="$t('scene.select-room')" filterable style="width: 150px">
          <el-option v-for="room in $store.getters.rooms" :label="room.name" :value="room.id" :key="room.id"></el-option>
        </el-select>
      </el-form-item>


      <!--<el-form-item prop="icon" label="Icon" slot="prepend">

        <el-select v-model="sceneData.icon" filterable placeholder="Select Icon">
          <el-option v-for="(icon, index) in icons" :key="index" :label="icon.title" :value="icon.value">
            <span><i :class="icon.value"></i> {{ icon.title}}</span>
          </el-option>
        </el-select>

        <span>
          <i :class="sceneData.icon" style="font-size: x-large; margin-left: 10px; color: #545c64"></i>
        </span>

      </el-form-item>-->


      <!--<el-form-item prop="roomId" label="Room">
        <el-select v-model="sceneData.roomId" placeholder="Select Room" filterable>
          <el-option v-for="room in $store.getters.rooms" :label="room.name" :value="room.id" :key="room.id"></el-option>
        </el-select>
      </el-form-item>-->

      <el-row>

        <el-col :span="6">
          <el-form-item prop="favorite" :label="$t('scene.favorite')">
            <el-switch v-model="sceneData.favorite"></el-switch>
          </el-form-item>
        </el-col>

        <el-col :span="6">
          <el-form-item prop="invisible" :label="$t('scene.invisible')">
            <el-switch v-model="sceneData.invisible"></el-switch>
          </el-form-item>
        </el-col>

        <el-col :span="6">
          <el-form-item prop="modifiable" :label="$t('scene.modifiable')">
            <el-switch v-model="sceneData.modifiable"></el-switch>
          </el-form-item>
        </el-col>

      </el-row>

    </el-form>


    <div class="bottom-container">
      <el-button type="primary" @click="onAddActionGroup"><i class="fa fa-plus"></i> {{ $t('scene.add-action-group') }}</el-button>

      <el-table :data="actionGroups" stripe>

        <el-table-column :label="$t('scene.delay')">
          <template slot-scope="scope">{{ delayString(scope.row) }}</template>
        </el-table-column>

        <el-table-column
                :label="$t('scene.action-count')">

          <template slot-scope="scope"> {{ $tc('scene.how-many-action', scope.row.actionList.length) }} </template>

        </el-table-column>

        <el-table-column
                align="right"
                :label="$t('scene.actions')">

          <template slot-scope="scope">
            <span @click="updateActionGroup(scope.row)"><i class="far fa-edit action-button"></i></span>
            <span @click="removeActionGroup(scope.row)"><i class="far fa-trash-alt action-button"></i></span>
          </template>

        </el-table-column>

      </el-table>

    </div>





  </div>
</template>

<script>
  import {className} from "@/utils/accessory-utils";

  export default {
    name: "SceneDetailForm",

    data() {
      return {
        sceneData: {
          actionSet: []
        },

        icons: [
          { title: this.$t('scene.icons[0]'), value: "ic_scene_default" },
          { title: this.$t('scene.icons[1]'), value: "ic_scene_cinema" },
          { title: this.$t('scene.icons[2]'), value: "ic_scene_cinema2" },
          { title: this.$t('scene.icons[3]'), value: "ic_scene_clean" },
          { title: this.$t('scene.icons[4]'), value: "ic_scene_coffee" },
          { title: this.$t('scene.icons[5]'), value: "ic_scene_dinner" },
          { title: this.$t('scene.icons[6]'), value: "ic_scene_game" },
          { title: this.$t('scene.icons[7]'), value: "ic_scene_goodbye" },
          { title: this.$t('scene.icons[8]'), value: "ic_scene_meeting" },
          { title: this.$t('scene.icons[9]'), value: "ic_scene_morning" },
          { title: this.$t('scene.icons[10]'), value: "ic_scene_night" },
          { title: this.$t('scene.icons[11]'), value: "ic_scene_party" },
          { title: this.$t('scene.icons[12]'), value: "ic_scene_party2" },
          { title: this.$t('scene.icons[13]'), value: "ic_scene_presentation" },
          { title: this.$t('scene.icons[14]'), value: "ic_scene_rain" },
          { title: this.$t('scene.icons[15]'), value: "ic_scene_reading_time" },
          { title: this.$t('scene.icons[16]'), value: "ic_scene_sleep_time" },
          { title: this.$t('scene.icons[17]'), value: "ic_scene_vacation" },
          { title: this.$t('scene.icons[18]'), value: "ic_scene_welcome" },
        ],

        loading: false,
        nextTitle: this.$t('scene.save'),

        rules: {
          name: [
            { required: true, trigger: 'blur' }
          ],
          room: [
            { required: true, trigger: 'blur' }
          ]
        }
      }
    },


    computed: {
      actionGroups() {
        if (this.sceneData.actionSet && this.sceneData.actionSet.length > 0)
          return this.sceneData.actionSet.sort((actionGroup1, actionGroup2) => actionGroup1.delayTime - actionGroup2.delayTime)
        return []
      },
    },

    methods: {
      className,

      onAddActionGroup(){
        this.$store.commit("setActionGroupEditMode", false)
        this.$emit('nextStep', 1)
      },

      onImageError: function(e){
        e.target.src = "/assets/icons/ic_scene_default.png";
      },


      delayString: function (actionGroup) {
        let second = actionGroup.delay % 60
        let minute = ((actionGroup.delay - second) % (60 * 60)) / 60
        let hour = parseInt(actionGroup.delay / (60 * 60))

        if (hour <= 0 && minute <= 0 && second <= 0)
          return this.$t('scene.immediate')
        return this.$tc('scene.hour-p', hour) + " " + this.$tc('scene.min-p', minute) + " " + this.$tc('scene.sec-p', second) + " " + this.$t('scene.delayed');
      },


      commandDescription(action) {
        console.log(action)

        let description = this.$store.getters.accessories.find(accessory => accessory.id == action.accessory.properties.id).name + " - " + action.service + " : "

        for (let [key, value] of Object.entries(action.argumentList)) {
          description += key + " -> " + value + ", "
        }
        return description
      },

      updateActionGroup: function (actionGroup) {
        this.$store.commit("setActionGroupEditMode", true)
        this.$store.commit("setActionGroupData", {...actionGroup})

        console.log(actionGroup)

        let selectedIds = {}
        actionGroup.actionList.forEach(action => {
          selectedIds[action.accessory.properties.id] = action.accessory.properties.id
        })

        let selectedAccessories = []
        for (let [key, value] of Object.entries(selectedIds)) {
          selectedAccessories.push(value)
        }

        console.log(selectedAccessories)

        this.$store.commit("setSelectedAccessories", selectedAccessories)

        this.$emit('nextStep', 1)
      },

      removeActionGroup: function (actionGroup) {
        this.sceneData.actionSet = this.sceneData.actionSet.filter(ag => {
          return ag.delay != actionGroup.delay
        })
      },

      onSaveScene() {
        this.$refs.form.validate(valid => {
          if (valid) {
            this.loading = true

            let formData = {...this.$store.getters.sceneFormData}

            let methodName = formData.editMode ? "editScene" : "addScene"

            console.log("onSaveScene", this.sceneData)

            this.$store.dispatch(methodName, this.sceneData).then(response => {
              this.loading = false

              this.$message({
                type: "success",
                message: this.$t(response.message)
              })

              this.$router.push({name: "Scenes"})
            }).catch(reason => {
              this.loading = false

              this.$message({
                type: "error",
                message: this.$t(reason)
              })
            })
          }
        })
      }
    },

    created(){
      console.log("Scene Detail created")

    },

    destroyed(){
      console.log("Scene destroyed")
      this.$store.commit("resetSceneFormData")
    },

    activated(){
      console.log("SceneDetail activated")
      let formData = {...this.$store.getters.sceneFormData}
      this.sceneData = {...formData.scene}
      console.log(this.sceneData)
    },

    deactivated(){
      console.log("SceneDetail deactivated")
    },

  }
</script>

<style scoped>
@import '../../../../style/automation.css';
  .action-button {
    font-size: large;
    padding: 10px;
    cursor: pointer;
  }

  .action-button:hover {
    background-color: #00BFD7;
    border-radius: 5px;
    color: white;
  }

  .action-button:active {
    box-shadow: 0px 0px 10px #00BFD7;
    border-radius: 5px;
  }

 .action-group-card {
   margin-right: 20px;
   margin-left: 20px;
   margin-top: 10px;
   margin-bottom: 10px;
 }

  .bottom-container {
    margin-right: 20px;
    margin-left: 20px;
    margin-top: 20px;
  }

  .icon-select-layout{
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .icon-select {
    filter: brightness(0.45);
    width: 24px;
    height: 24px;
    margin-right: 5px;
  }

  .icon-contact {
    filter: brightness(0.45);
    width: 40px;
    height: 40px;
  }
</style>