<template>
  <el-card style="min-height: 345px">
    <Header slot="header" :title="accessory.name" :subTitle="roomName" :icon="accessory.icon" :badge="actions.length" @onChangePage="onChangePage"></Header>

    <div class="content-container">

      <div v-if="currentPage == 0" style="height: 230px;">
        <div class="cover-container">
          <i class="fa fa-music" :style="{color: accessory.playing ? '#00BFD7' : 'gray', fontSize: 50 + 'px'}"></i>
        </div>

        <div class="playback-container">
          <div @click="onShuffle" class="repeat-shuffle-button" :style="{color: accessory.shuffle ? '#00BFD7' : 'gray'}"><i class="fa fa-random"></i></div>
          <el-button @click="onPrevious" type="primary" size="small" circle><i class="fa fa-backward"></i></el-button>
          <el-button @click="onPlay" type="primary" circle><i :class="{'fa fa-play': !accessory.playing, 'fa fa-pause': accessory.playing}"></i></el-button>
          <el-button @click="onNext" type="primary" size="small" circle><i class="fa fa-forward"></i></el-button>
          <div @click="onRepeat" class="repeat-shuffle-button" :style="{color: accessory.repeat ? '#00BFD7' : 'gray'}"><i class="fa fa-retweet"></i></div>
        </div>
        
        <div class="volume-container">
          <div @click="onMute" class="mute-button"><i :class="{'fa fa-volume-off': accessory.mute, 'fa fa-volume-up': !accessory.mute}" style="font-size: 24px; color: gray"></i></div>
          <el-slider style="width: 100%" v-model="accessory.volume" @change="onChangeVolume"></el-slider>
        </div>
        
      </div>

      <ActionList v-else :actions="actions" :currentPage="currentPage" @onChange="onChangeActions" />

    </div>
  </el-card>
</template>

<script>
import { accessoryMixin } from "./accessoryMixin";

export default {
  name: "Audio",

  mixins: [accessoryMixin],

  methods: {
    onChangeVolume: function() {
      this.createAction(
        "runPlaybackAction",
        "volume",
        this.accessory.volume,
        `Volume(${this.accessory.volume})`
      );
    },
    onPlay: function() {
      this.accessory.playing = !this.accessory.playing;
      this.createAction(
        "runPlaybackAction",
        "play",
        this.accessory.playing ? 1 : 0,
        this.accessory.playing ? "Play" : "Pause"
      );
    },
    onMute: function() {
      this.accessory.mute = !this.accessory.mute;
      this.createAction(
        "runPlaybackAction",
        "mute",
        this.accessory.mute ? 1 : 0,
        this.accessory.mute ? "Mute" : "Unmute"
      );
    },
    onShuffle: function() {
      this.accessory.shuffle = !this.accessory.shuffle;
      this.createAction(
        "runPlaybackAction",
        "shuffle",
        this.accessory.shuffle ? 1 : 0,
        this.accessory.shuffle ? "Mix" : "No Mix"
      );
    },
    onRepeat: function() {
      this.accessory.repeat = !this.accessory.repeat;
      this.createAction(
        "runPlaybackAction",
        "repeat",
        this.accessory.repeat ? 1 : 0,
        this.accessory.repeat ? "Repeat" : "No Repeat"
      );
    },
    onPrevious: function() {
      this.createAction("runPlaybackAction", "previous", null, "Previous");
    },
    onNext: function() {
      this.createAction("runPlaybackAction", "next", null, "Next");
    },

    addSceneAction(action) {
      var i = 0;
      for (; i < this.actions.length; i++) {
        if (
          this.actions[i].argumentList.targetMode ===
          action.argumentList.targetMode
        ) {
          this.actions[i] = { ...action };
          break;
        }
      }

      if (i == this.actions.length) {
        this.actions.push({ ...action });
      }

      this.$emit("onAction", {
        accessory: this.accessory,
        actions: [...this.actions],
      });
    },

    createAction: function(service, paramMode, paramValue, paramTitle) {
      console.log("createAction", service, paramValue, paramTitle);

      const params = {};
      if (paramValue != null && paramValue != undefined) {
        params.newTargetValue = paramValue;
      }

      if (paramMode != null && paramMode != undefined) {
        params.targetMode = paramMode;
      }

      if (paramTitle) {
        params.valueTitle = paramTitle;
      }

      const action = {
        accessory: {
          type: this.className(this.accessory.category),
          properties: { ...this.accessory },
        },
        service: service,
        argumentList: params,
      };

      console.log("createAction", action);

      this.addSceneAction(action);
    },
  },
};
</script>

<style scoped>
.progress {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 180px;
}

.column-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 250px;
  height: 230px;
}

.content-container {
  height: 230px;
}

.action-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.cover-container {
  background-color: #eeeeee;
  border-radius: 10px;
  width: 40%;
  aspect-ratio: 1 / 1;
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
}
.playback-container {
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin-top: 20px;
}
.volume-container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-top: 20px;
}
.mute-button {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  margin-right: 10px;
}
.mute-button:hover {
  background-color: #eeeeee;
  border-radius: 4px;
  cursor: pointer;
}

.repeat-shuffle-button {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
}
.repeat-shuffle-button:hover {
  background-color: #eeeeee;
  border-radius: 4px;
  cursor: pointer;
}
.el-card {
      border: 1px solid #ebeef5;
      border-radius: 10px;
  }
</style>
