<template>
  <el-card style="min-height: 345px">
    <Header
      slot="header"
      :title="accessory.name"
      :subTitle="roomName"
      :icon="accessory.icon"
      :badge="actions.length"
      @onChangePage="onChangePage"
    ></Header>

    <div class="content-container">
      <div v-if="currentPage == 0">
        <el-tabs stretch v-model="tabValue" tab-position="bottom">
          <el-tab-pane name="brightness" label="Brightness">
            <el-row>
              <el-col :span="12" class="column-container">
                <div class="mode-control">
                  <div class="mode-button" :style="{color: accessory.powerOn ? '#00BFD7' : '#999999'}" @click="onSwitch(true)">
                    I
                  </div>
                  <div class="mode-button" :style="{color: !accessory.powerOn ? '#00BFD7' : '#999999'}" @click="onSwitch(false)">
                    O
                  </div>
                </div>
                <br />
                <!-- <div>{{ status }}</div> -->
              </el-col>
              <el-col :span="12" class="column-container">
                <el-slider
                  vertical
                  class="slider-container"
                  v-model="accessory.level"
                  height="120px"
                  @change="onChangeLevel"
                ></el-slider>
                <br />
                {{ $t("scene.level") }}
              </el-col>
            </el-row>
          </el-tab-pane>

          <el-tab-pane name="color" label="Color">
            <div class="column-container">
              <chrome-picker disableAlpha disableFields v-model="colors" />
              <el-slider
                style="width: 80%; margin-bottom: 20px"
                :max="255"
                v-model="accessory.white"
                @change="onChangeWhite"
              ></el-slider>
            </div>
          </el-tab-pane>
        </el-tabs>
      </div>

      <ActionList
        v-else
        :actions="actions"
        :currentPage="currentPage"
        @onChange="onChangeActions"
      />
    </div>
  </el-card>
</template>

<script>
import { accessoryMixin } from "./accessoryMixin";
import {
  Material,
  Compact,
  Swatches,
  Slider,
  Sketch,
  Chrome,
  Photoshop,
} from "vue-color";

export default {
  name: "RGBWDimmer",

  mixins: [accessoryMixin],

  components: {
    "material-picker": Material,
    "compact-picker": Compact,
    "swatches-picker": Swatches,
    "slider-picker": Slider,
    "sketch-picker": Sketch,
    "chrome-picker": Chrome,
    "photoshop-picker": Photoshop,
  },

  data: function() {
    return {
      status: "",
      colors: { r: 255, g: 0, b: 0 },
      tabValue: "brightness",
    };
  },

  watch: {
    colors: {
      handler: function(val) {
        const red = val.rgba.r;
        const green = val.rgba.g;
        const blue = val.rgba.b;

        //const color = (red << 16) + (green << 8) + blue;
        const color =
          ((red & 0xff) << 16) | ((green & 0xff) << 8) | (blue & 0xff);
        this.accessory.rgb = color;
        console.log("colors", val, color);
        this.onChangeColor();
      },
      deep: true,
    },
  },

  methods: {
    onSwitch: function(value) {
      this.accessory.powerOn = value;
      this.accessory.level = this.accessory.powerOn ? 100 : 0;

      this.createAction("setOn", this.accessory.powerOn);
      this.setStatus();
    },

    onChangeLevel: function() {
      if (this.accessory.level > 0) this.accessory.powerOn = true;
      else this.accessory.powerOn = false;

      this.createAction("setDeviceLevel", this.accessory.level);
      this.setStatus();
    },

    onChangeColor: function() {
      // const r = (this.accessory.rgb & (255 << 16)) >> 16;
      // const g = (this.accessory.rgb & (255 << 8)) >> 8;
      // const b = this.accessory.rgb & 255;

      const r = (this.accessory.rgb >> 16) & 0xff;
      const g = (this.accessory.rgb >> 8) & 0xff;
      const b = this.accessory.rgb & 0xff;
      console.log("onChangeColor", r, g, b);
      this.createAction(
        "setRGB",
        this.accessory.rgb,
        `RGB(${this.colors.rgba.r}, ${this.colors.rgba.g}, ${
          this.colors.rgba.b
        })`
      );
    },

    onChangeWhite: function() {
      this.createAction("setWhite", this.accessory.white);
    },

    setStatus: function() {
      this.status = this.accessory.powerOn
        ? this.$t("scene.on")
        : this.$t("scene.off");
    },
  },

  created() {
    if (this.accessory.actionMode) this.accessory.actionMode = "";
  },
};
</script>

<style scoped>
.column-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 180px;
}

.slider-container {
}
/*
.mode-control {
  border: 1px solid #cccccc;
  border-radius: 8px;
  padding-top: 2px;
  padding-bottom: 2px;
}

  .mode-button {
    cursor: pointer;
    padding: 10px;
    margin: 2px;
    color: #ccc;
    text-align: center;
  } */
.mode-control {
  border: 1px solid #cccccc;
  border-radius: 8px;
  padding-top: 5px;
  padding-bottom: 5px;
}
.mode-button {
  cursor: pointer;
  padding: 8px;
  margin: 2px;
  font-size: 20px;
  text-align: center;
}

.mode-button:hover {
  color: dodgerblue;
}

.content-container {
  height: 230px;
}    
.el-card {
      border: 1px solid #ebeef5;
      border-radius: 10px;
  }
</style>
