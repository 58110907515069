<template>
  <el-card style="min-height: 345px">
    <Header slot="header" :title="accessory.name" :subTitle="roomName" :icon="accessory.icon" :badge="actions.length" @onChangePage="onChangePage"></Header>

    <div class="content-container">

      <div v-if="currentPage == 0" @click="onSwitch" class="progress">
        <el-progress type="circle" :percentage="0" status="text"><span class="run-text">{{ $t('scene.run') }}</span></el-progress>
      </div>

      <ActionList v-else :actions="actions" :currentPage="currentPage" @onChange="onChangeActions" />

    </div>

  </el-card>
</template>

<script>
  import {accessoryMixin} from "./accessoryMixin";

  export default {
    name: "Punch",

    mixins: [accessoryMixin],

    methods: {
      onSwitch: function () {
        this.createAction("punch")
      }
    },
  }
</script>

<style scoped>

  .progress {
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 230px;
  }

  .run-text {
    padding: 50px;
    border-radius: 70px;
  }

  .run-text:active {
    box-shadow: 0px 0px 10px #00BFD7;
    border-radius: 100px;
  }

  .content-container {
    height: 230px;
  }
  .el-card {
      border: 1px solid #ebeef5;
      border-radius: 10px;
  }
</style>