<template>
  <el-card style="min-height: 345px">
    <Header slot="header" :title="accessory.name" :subTitle="roomName" :icon="accessory.icon" :badge="actions.length" @onChangePage="onChangePage"></Header>

    <div class="content-container">

      <div v-if="currentPage == 0" style="height: 230px">
        <el-row>
          <el-col :span="8" class="column-container">
            <div class="mode-control">
                  <div class="mode-button" :style="{color: accessory.powerOn ? '#00BFD7' : '#999999'}" @click="onSetStatus(true)">I</div>
                  <div class="mode-button" :style="{color: !accessory.powerOn ? '#00BFD7' : '#999999'}" @click="onSetStatus(false)">O</div>
            </div>
          </el-col>
          <el-col :span="8">
            <div class="progress" @click="onSwitch" style="cursor: pointer">
              <el-progress type="circle" color="#00BFD7" :percentage="accessory.level"></el-progress>
            </div>
          </el-col>
          <!-- <el-col :span="8" justify="end">
            <el-switch :value="accessory.powerOn" @change="onSwitch"></el-switch>
          </el-col> -->
        </el-row>
        <el-slider v-model="accessory.level" @change="onChangeLevel"></el-slider>
      </div>

      <ActionList v-else :actions="actions" :currentPage="currentPage" @onChange="onChangeActions" />

    </div>

  </el-card>
</template>

<script>
  import {accessoryMixin} from "./accessoryMixin";

  export default {
    name: "Dimmer",

    mixins: [accessoryMixin],

    methods: {
      onSwitch: function () {
        this.accessory.powerOn = !this.accessory.powerOn
        this.accessory.level = this.accessory.powerOn ? 100 : 0

        this.createAction("setOn", this.accessory.powerOn)
      },
      onSetStatus: function (status) {
        this.accessory.powerOn = status
        this.accessory.level = status ? 100 : 0

        this.createAction("setOn", this.accessory.powerOn)
      },

      onChangeLevel: function () {
        if (this.accessory.level > 0)
          this.accessory.powerOn = true
        else
          this.accessory.powerOn = false

        this.createAction("setDeviceLevel", this.accessory.level)
      }
    }
  }
</script>

<style scoped>

  .progress {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 180px;
  }

  .column-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 180px;
  }

  .content-container {
    height: 230px;
  }

  .mode-control {
  border: 1px solid #cccccc;
  border-radius: 8px;
  padding-top: 5px;
  padding-bottom: 5px;
}
.mode-button {
  cursor: pointer;
  padding: 8px;
  margin: 2px;
  font-size: 20px;
  text-align: center;
}
.el-card {
      border: 1px solid #ebeef5;
      border-radius: 10px;
  }
</style>