var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticStyle: {
        display: "flex",
        "justify-content": "space-between",
        "align-items": "flex-end"
      }
    },
    [
      _c("div", [
        _c("span", { staticClass: "title" }, [
          _vm._v(" " + _vm._s(_vm.title) + " ")
        ]),
        _c("br"),
        _c("span", { staticClass: "subtitle" }, [
          _vm._v(" " + _vm._s(_vm.subTitle) + " ")
        ])
      ]),
      _c(
        "div",
        {
          staticStyle: {
            display: "flex",
            "justify-content": "space-between",
            "align-items": "flex-end"
          }
        },
        [
          _c(
            "div",
            {
              staticClass: "tabButton",
              on: {
                click: function($event) {
                  _vm.page = 0
                }
              }
            },
            [
              _vm._m(0),
              _c("br"),
              _c("div", {
                style: {
                  backgroundColor: _vm.page == 1 ? "#00000000" : "#fff",
                  height: "2px"
                }
              })
            ]
          ),
          _vm.badge > 0
            ? _c(
                "div",
                {
                  staticClass: "tabButton",
                  on: {
                    click: function($event) {
                      _vm.page = 1
                    }
                  }
                },
                [
                  _c("el-badge", { attrs: { value: _vm.badge } }, [
                    _c("span", { staticClass: "tabtitle" }, [
                      _c("i", {
                        staticClass: "fas fa-sliders-h",
                        staticStyle: { "font-size": "large" }
                      })
                    ])
                  ]),
                  _c("br"),
                  _c("div", {
                    style: {
                      backgroundColor: _vm.page == 0 ? "#00000000" : "#fff",
                      height: "2px"
                    }
                  })
                ],
                1
              )
            : _vm._e()
        ]
      ),
      _c("img", {
        staticClass: "icon",
        attrs: { src: "/assets/icons/" + _vm.icon + ".png", alt: "" }
      })
    ]
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticClass: "tabtitle" }, [
      _c("i", {
        staticClass: "fas fa-hand-point-up",
        staticStyle: { "font-size": "large" }
      })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }