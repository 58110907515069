import {className} from "../../../../utils/accessory-utils";
import Header from "./Header";
import ActionList from "./ActionList";

export const accessoryMixin = {
  components: {Header, ActionList},

  props: {
    sceneActionData: {
      type: Object,
      required: true,
      default: {}
    }
  },

  data: function(){
    return {
      accessory: {},
      actions: [],
      currentPage: 0
    }
  },

  computed: {
    roomName: function () {
      var room = this.$store.getters.rooms.find(room => room.id == this.accessory.roomId)
      if (room)
        return room.name
      return this.$t('scene.no-room')
    }
  },

  methods: {
    className,

    addSceneAction(action){
      var i = 0
      for (; i < this.actions.length; i++) {
        if (this.actions[i].service === action.service) {
          this.actions[i] = {...action}
          break;
        } else if (["actionOn", "actionOff"].includes(action.service) && ["actionOn", "actionOff"].includes(this.actions[i].service)) {
          this.actions[i] = {...action}
          break;
        }
      }

      if (i == this.actions.length) {
        this.actions.push({...action});
      }

      this.$emit("onAction", {accessory: this.accessory, actions: [...this.actions]})
    },

    onChangePage: function (page) {
      this.currentPage = page
      console.log("onChangePage", this.currentPage)
    },

    onChangeActions: function (actionList) {
      this.actions = [...actionList]

      console.log("onChangeActions", this.actions.length, actionList.length)

      if (this.actions.length == 0) {
        this.currentPage = 0
      }

      this.$emit("onAction", {accessory: this.accessory, actions: [...this.actions]})
    },

    createAction: function (service, paramValue, paramTitle) {

      console.log("createAction", service, paramValue, paramTitle)

      const params = {}
      if (paramValue != null && paramValue != undefined) {
        params.newTargetValue = paramValue
      }

      if (paramTitle) {
        params.valueTitle = paramTitle
      }

      const action = {
        accessory: {
          type: this.className(this.accessory.category),
          properties: {...this.accessory}
        },
        service: service,
        argumentList: params
      }

      console.log("createAction", action)

      this.addSceneAction(action)
    }
  },

  created() {
    this.accessory = {...this.sceneActionData.accessory}
    this.actions = [...this.sceneActionData.actions]
  }
}