import { render, staticRenderFns } from "./TunableWhite.vue?vue&type=template&id=705c86cc&scoped=true&"
import script from "./TunableWhite.vue?vue&type=script&lang=js&"
export * from "./TunableWhite.vue?vue&type=script&lang=js&"
import style0 from "./TunableWhite.vue?vue&type=style&index=0&id=705c86cc&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "705c86cc",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("C:\\Users\\sinan\\Desktop\\Core Projects\\CoreOS-V4-develop\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('705c86cc')) {
      api.createRecord('705c86cc', component.options)
    } else {
      api.reload('705c86cc', component.options)
    }
    module.hot.accept("./TunableWhite.vue?vue&type=template&id=705c86cc&scoped=true&", function () {
      api.rerender('705c86cc', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/automation/scene/components/TunableWhite.vue"
export default component.exports