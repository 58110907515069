var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-row",
        { staticStyle: { "margin-left": "20px", "margin-right": "20px" } },
        [
          _c(
            "el-col",
            { attrs: { span: 12 } },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "text", icon: "el-icon-d-arrow-left" },
                  nativeOn: {
                    click: function($event) {
                      $event.preventDefault()
                      return _vm.$emit("prevStep")
                    }
                  }
                },
                [_vm._v(_vm._s(_vm.$t("scene.back")))]
              )
            ],
            1
          ),
          _c(
            "el-col",
            { attrs: { span: 12 } },
            [
              _c(
                "el-button",
                {
                  staticStyle: { float: "right" },
                  attrs: { type: "text" },
                  nativeOn: {
                    click: function($event) {
                      $event.preventDefault()
                      return _vm.nextStep($event)
                    }
                  }
                },
                [
                  _vm._v(_vm._s(_vm.$t("scene.next")) + "\n        "),
                  _c("i", { staticClass: "el-icon-d-arrow-right" })
                ]
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "el-row",
        { attrs: { gutter: 10 } },
        _vm._l(_vm.selectedAccessories, function(accessoryId) {
          return _c(
            "el-col",
            {
              key: accessoryId,
              staticStyle: { "margin-top": "10px" },
              attrs: { span: 8 }
            },
            [
              _vm.className(_vm.getAccessory(accessoryId).category) ==
                "HHAccessorySwitch" &&
              _vm.getAccessory(accessoryId).category == 27
                ? _c("punchComponent", {
                    tag: "component",
                    attrs: { sceneActionData: _vm.getActionData(accessoryId) },
                    on: { onAction: _vm.onAction }
                  })
                : _vm.className(_vm.getAccessory(accessoryId).category) ==
                    "HHAccessorySwitch" &&
                  _vm.getAccessory(accessoryId).category == 28
                ? _c("binaryComponent", {
                    tag: "component",
                    attrs: { sceneActionData: _vm.getActionData(accessoryId) },
                    on: { onAction: _vm.onAction }
                  })
                : _vm.className(_vm.getAccessory(accessoryId).category) ==
                  "HHAccessorySwitch"
                ? _c("switchComponent", {
                    tag: "component",
                    attrs: { sceneActionData: _vm.getActionData(accessoryId) },
                    on: { onAction: _vm.onAction }
                  })
                : _vm.className(_vm.getAccessory(accessoryId).category) ==
                  "HHAccessoryDimmer"
                ? _c("dimmerComponent", {
                    tag: "component",
                    attrs: { sceneActionData: _vm.getActionData(accessoryId) },
                    on: { onAction: _vm.onAction }
                  })
                : _vm.className(_vm.getAccessory(accessoryId).category) ==
                  "HHAccessoryWindowCovering"
                ? _c("shutterComponent", {
                    tag: "component",
                    attrs: { sceneActionData: _vm.getActionData(accessoryId) },
                    on: { onAction: _vm.onAction }
                  })
                : _vm.className(_vm.getAccessory(accessoryId).category) ==
                  "HHAccesssoryThermostat"
                ? _c("hvacComponent", {
                    tag: "component",
                    attrs: { sceneActionData: _vm.getActionData(accessoryId) },
                    on: { onAction: _vm.onAction }
                  })
                : _vm.className(_vm.getAccessory(accessoryId).category) ==
                  "HHAccessoryAdvThermostat"
                ? _c("advHvacComponent", {
                    tag: "component",
                    attrs: { sceneActionData: _vm.getActionData(accessoryId) },
                    on: { onAction: _vm.onAction }
                  })
                : _vm.className(_vm.getAccessory(accessoryId).category) ==
                  "HHAccessoryDoorLock"
                ? _c("doorlockComponent", {
                    tag: "component",
                    attrs: { sceneActionData: _vm.getActionData(accessoryId) },
                    on: { onAction: _vm.onAction }
                  })
                : _vm.className(_vm.getAccessory(accessoryId).category) ==
                  "HHAccessoryAudio"
                ? _c("audioComponent", {
                    tag: "component",
                    attrs: { sceneActionData: _vm.getActionData(accessoryId) },
                    on: { onAction: _vm.onAction }
                  })
                : _vm.className(_vm.getAccessory(accessoryId).category) ==
                  "HHAccessoryRGBWDimmer"
                ? _c("rgbwComponent", {
                    tag: "component",
                    attrs: { sceneActionData: _vm.getActionData(accessoryId) },
                    on: { onAction: _vm.onAction }
                  })
                : _vm.className(_vm.getAccessory(accessoryId).category) ==
                  "HHAccessoryTunableWhite"
                ? _c("tunableWhiteComponent", {
                    tag: "component",
                    attrs: { sceneActionData: _vm.getActionData(accessoryId) },
                    on: { onAction: _vm.onAction }
                  })
                : _vm._e()
            ],
            1
          )
        }),
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }