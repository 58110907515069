<template>
  <el-card style="min-height: 345px">
    <Header slot="header" :title="accessory.name" :subTitle="roomName" :icon="accessory.icon" :badge="actions.length" @onChangePage="onChangePage"></Header>

    <div class="content-container">

      <el-row v-if="currentPage == 0">
        <el-col :span="8" class="column-container">

          <div class="mode-control">
            <div class="mode-button" :style="{color: accessory.thermoMode == 'thermoModeOff' ? '#545c64' : '#CCCCCC'}" @click="onSetThermoMode('thermoModeOff')">
              {{ $t('scene.thermo-mode-off') }}
            </div>
            <div class="mode-button" :style="{color: accessory.thermoMode == 'thermoModeHeat' ? 'red' : '#CCCCCC'}" @click="onSetThermoMode('thermoModeHeat')">
              <i class="far fa-sun fa-2x"></i>
            </div>
            <div class="mode-button" :style="{color: accessory.thermoMode == 'thermoModeCool' ? 'dodgerblue' : '#CCCCCC'}" @click="onSetThermoMode('thermoModeCool')">
              <i class="far fa-snowflake fa-2x"></i>
            </div>
          </div>


        </el-col>

        <el-col :span="8" class="column-container">
          <div class="increase-button" @click="changeTemperature(1)"><i class="fa fa-angle-up fa-2x"></i></div>
          <div class="progress">
            <el-progress type="circle" :percentage="percentage" :color="color" status="text">{{ status }}<span v-if="status != $t('scene.thermo-mode-off')">&#176;</span></el-progress>
          </div>

          <div class="increase-button" @click="changeTemperature(-1)"><i class="fa fa-angle-down fa-2x"></i></div>
        </el-col>

        <el-col :span="8" class="column-container">
          <div>{{ $t('scene.current') }}</div>
          <div>{{ accessory.temperature }}&#176;</div>
        </el-col>

      </el-row>

      <ActionList v-else :actions="actions" :currentPage="currentPage" @onChange="onChangeActions" />

    </div>

  </el-card>
</template>

<script>
  import {accessoryMixin} from "./accessoryMixin";

  export default {
    name: "Hvac",

    mixins: [accessoryMixin],

    computed: {
      percentage: function () {
        return this.accessory.thermoMode == "thermoModeOff" ? 0 : (this.accessory.thermoMode == "thermoModeHeat" ? 100 * this.accessory.heatSetPoint / 35 : 100 * this.accessory.coolSetPoint / 35)
      },

      status: function () {
        if (this.accessory.thermoMode == "thermoModeOff")
          return this.$t('scene.thermo-mode-off')
        else if (this.accessory.thermoMode == "thermoModeCool")
          return this.accessory.coolSetPoint
        else
          return this.accessory.heatSetPoint
      },

      color: function () {
        if (this.accessory.thermoMode == "thermoModeOff")
          return "#CCCCCC"
        else if (this.accessory.thermoMode == "thermoModeCool")
          return "dodgerblue"
        else
          return "red"
      }
    },

    methods: {
      onSetThermoMode: function (thermoMode) {
        this.accessory.thermoMode = thermoMode
        this.createAction("setThermoMode", this.accessory.thermoMode, this.accessory.thermoMode == "thermoModeCool" ? "Cool" : (this.accessory.thermoMode == "thermoModeHeat" ? "Heat" : "Off"))
      },

      changeTemperature: function (temperature) {
        if (this.accessory.thermoMode == "thermoModeHeat")
          this.accessory.heatSetPoint += temperature
        else if (this.accessory.thermoMode == "thermoModeCool")
          this.accessory.coolSetPoint += temperature

        this.accessory.heatSetPoint = this.accessory.heatSetPoint > 35 ? 35 : this.accessory.heatSetPoint
        this.accessory.heatSetPoint = this.accessory.heatSetPoint < 0 ? 0 : this.accessory.heatSetPoint

        this.accessory.coolSetPoint = this.accessory.coolSetPoint > 35 ? 35 : this.accessory.coolSetPoint
        this.accessory.coolSetPoint = this.accessory.coolSetPoint < 0 ? 0 : this.accessory.coolSetPoint

        const action = {
          accessory: {
            type: this.className(this.accessory.category),
            properties: {...this.accessory}
          },
          service: "setTemperature",
          argumentList: {
            targetMode: this.accessory.thermoMode,
            newTargetValue: this.accessory.thermoMode == "thermoModeCool" ? this.accessory.coolSetPoint : (this.accessory.thermoMode == "thermoModeHeat" ? this.accessory.heatSetPoint : this.accessory.temperature)
          }
        }

        this.addSceneAction(action)
      },
    }
  }
</script>

<style scoped>
  .icon {
    filter: brightness(0.45);
    width: 30px;
    height: 30px;
  }

  .title {
    font-size: small;
    font-weight: bold;
    color: #545c64;
  }

  .subtitle {
    font-size: smaller;
    color: #545c64;
  }

  .column-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 230px;
  }

  .progress {
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .mode-control {
    border: 1px solid #cccccc;
    border-radius: 8px;
    padding-top: 5px;
    padding-bottom: 5px;
  }

  .mode-button {
    cursor: pointer;
    padding: 10px;
    margin: 2px;
    color: #ccc;
  }

  .increase-button{
    color: #545c64;
    cursor: pointer;

  }

  .increase-button:hover{
    color: dodgerblue;
  }

  .content-container {
    height: 230px;
  }
  .el-card {
      border: 1px solid #ebeef5;
      border-radius: 10px;
  }
</style>