<template>
  <el-card style="min-height: 345px">
    <Header slot="header" :title="accessory.name" :subTitle="roomName" :icon="accessory.icon" :badge="actions.length" @onChangePage="onChangePage"></Header>

    <div class="content-container">

      <el-row v-if="currentPage == 0">
        <el-col :span="8" class="column-container">
          <el-slider vertical class="slider-container" v-model="accessory.slat" height="150px" @change="onChangeSlat"></el-slider>
          <br>
          {{ $t('scene.slat') }}
        </el-col>
        <el-col :span="8" class="column-container">
          <div class="mode-control">
            <div class="mode-button" @click="onOpened">
              <i class="fa fa-angle-up fa-2x"></i>
            </div>
            <div class="mode-button" @click="onStopped">
              <i class="fa fa-minus fa-2x"></i>
            </div>
            <div class="mode-button" @click="onClosed">
              <i class="fa fa-angle-down fa-2x"></i>
            </div>
          </div>
          <br>
          <div>{{ status }}</div>
        </el-col>
        <el-col :span="8" class="column-container">
          <el-slider vertical class="slider-container" v-model="accessory.level" height="150px" @change="onChangeLevel"></el-slider>
          <br>
          {{ $t('scene.level') }}
        </el-col>
      </el-row>

      <ActionList v-else :actions="actions" :currentPage="currentPage" @onChange="onChangeActions" />

    </div>

  </el-card>
</template>

<script>
  import {accessoryMixin} from "./accessoryMixin";

  export default {
    name: "Shutter",

    mixins: [accessoryMixin],

    data: function(){
      return {
        status: ""
      }
    },


    methods: {
      onOpened: function () {
        this.accessory.actionMode = "actionOpen"
        this.createAction("setWindowCoveringAction", this.accessory.actionMode, "Open")
        this.setStatus()
      },

      onStopped: function () {
        this.accessory.actionMode = "actionStop"
        this.createAction("setWindowCoveringAction", this.accessory.actionMode, "Stop")
        this.setStatus()
      },

      onClosed: function () {
        this.accessory.actionMode = "actionClose"
        this.createAction("setWindowCoveringAction", this.accessory.actionMode, "Close")
        this.setStatus()
      },

      onChangeLevel: function () {
        this.accessory.actionMode = ""
        this.createAction("setDeviceLevel", this.accessory.level)
        this.setStatus()
      },

      onChangeSlat: function () {
        this.createAction("setSlatLevel", this.accessory.slat)
      },

      setStatus: function () {
        this.status = this.accessory.actionMode == 'actionStop'
          ? this.$t('scene.stopped') : (this.accessory.level > 0 || this.accessory.actionMode == 'actionOpen'
            ? this.$t('scene.opened') : this.$t('scene.closed'))
      }
    },

    created(){
      if (this.accessory.actionMode)
        this.accessory.actionMode = ""
    }
  }
</script>

<style scoped>

  .column-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 230px;
  }

  .slider-container {

  }

  .mode-control {
    border: 1px solid #cccccc;
    border-radius: 8px;
    padding-top: 2px;
    padding-bottom: 2px;
  }

  .mode-button {
    cursor: pointer;
    padding: 8px;
    margin: 2px;
    color: #999;
    text-align: center;
  }

  .mode-button:hover {
    color: dodgerblue;
  }

  .content-container {
    height: 230px;
  }
  .el-card {
      border: 1px solid #ebeef5;
      border-radius: 10px;
  }
</style>