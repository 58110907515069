<template>
  <el-card style="min-height: 345px">
    <Header slot="header" :title="accessory.name" :subTitle="roomName" :icon="accessory.icon" :badge="actions.length" @onChangePage="onChangePage"></Header>

    <div class="content-container">

      <div v-if="currentPage == 0" @click="onSwitch" class="progress">
        <el-progress type="circle" :percentage="accessory.powerOn ? 100 : 0" status="text">{{ accessory.powerOn ? $t('scene.lock') : $t('scene.unlock') }}</el-progress>
      </div>

      <ActionList v-else :actions="actions" :currentPage="currentPage" @onChange="onChangeActions" />

    </div>

  </el-card>
</template>

<script>
  import {accessoryMixin} from "./accessoryMixin";

  export default {
    name: "DoorLock",

    mixins: [accessoryMixin],

    methods: {
      onSwitch: function () {
        this.accessory.powerOn = !this.accessory.powerOn
        this.createAction("setOn", this.accessory.powerOn)
      }
    },
  }
</script>

<style scoped>
  .icon {
    filter: brightness(0.45);
    width: 40px;
    height: 40px;
  }
  .title {
    font-size: small;
    font-weight: bold;
    color: #545c64;
  }

  .subtitle {
    font-size: smaller;
    color: #545c64;
  }

  .progress {
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 230px;
  }

  .content-container {
    height: 230px;
  }
  .el-card {
      border: 1px solid #ebeef5;
      border-radius: 10px;
  }
</style>