<template>
  <div>
    <el-row style="margin-left: 20px; margin-right: 20px">
      <el-col :span="12">
        <el-button  type="text" @click.native.prevent="$emit('prevStep')" icon="el-icon-d-arrow-left">{{ $t('scene.back') }}</el-button>
      </el-col>

      <el-col :span="12">
        <el-button style="float: right" type="text" @click.native.prevent="nextStep">{{ $t('scene.next') }}
          <i class="el-icon-d-arrow-right"></i>
        </el-button>
      </el-col>

    </el-row>

    <el-row :gutter="10">
      <el-col v-for="accessoryId in selectedAccessories" :key="accessoryId" :span="8" style="margin-top: 10px">

        <component v-if="className(getAccessory(accessoryId).category) == 'HHAccessorySwitch' && getAccessory(accessoryId).category == 27"
                   is="punchComponent" :sceneActionData="getActionData(accessoryId)" @onAction="onAction"/>

        <component v-else-if="className(getAccessory(accessoryId).category) == 'HHAccessorySwitch' && getAccessory(accessoryId).category == 28"
                   is="binaryComponent" :sceneActionData="getActionData(accessoryId)" @onAction="onAction"/>

        <component v-else-if="className(getAccessory(accessoryId).category) == 'HHAccessorySwitch'"
                   is="switchComponent" :sceneActionData="getActionData(accessoryId)" @onAction="onAction"/>

        <component v-else-if="className(getAccessory(accessoryId).category) == 'HHAccessoryDimmer'"
                   is="dimmerComponent" :sceneActionData="getActionData(accessoryId)" @onAction="onAction"/>

        <component v-else-if="className(getAccessory(accessoryId).category) == 'HHAccessoryWindowCovering'"
                   is="shutterComponent" :sceneActionData="getActionData(accessoryId)" @onAction="onAction"/>

        <component v-else-if="className(getAccessory(accessoryId).category) == 'HHAccesssoryThermostat'"
                   is="hvacComponent" :sceneActionData="getActionData(accessoryId)" @onAction="onAction"/>

        <component v-else-if="className(getAccessory(accessoryId).category) == 'HHAccessoryAdvThermostat'"
                   is="advHvacComponent" :sceneActionData="getActionData(accessoryId)" @onAction="onAction"/>

        <component v-else-if="className(getAccessory(accessoryId).category) == 'HHAccessoryDoorLock'"
                   is="doorlockComponent" :sceneActionData="getActionData(accessoryId)" @onAction="onAction"/>

        <component v-else-if="className(getAccessory(accessoryId).category) == 'HHAccessoryAudio'"
        is="audioComponent" :sceneActionData="getActionData(accessoryId)" @onAction="onAction"/>

        <component v-else-if="className(getAccessory(accessoryId).category) == 'HHAccessoryRGBWDimmer'"
        is="rgbwComponent" :sceneActionData="getActionData(accessoryId)" @onAction="onAction"/>
        
        <component v-else-if="className(getAccessory(accessoryId).category) == 'HHAccessoryTunableWhite'"
        is="tunableWhiteComponent" :sceneActionData="getActionData(accessoryId)" @onAction="onAction"/>


      </el-col>
    </el-row>
  </div>

</template>

<script>
import Switch from "./Switch";
import Dimmer from "./Dimmer";
import Audio from "./Audio";
import Shutter from "./Shutter";
import Hvac from "./Hvac";
import AdvHvac from "./AdvHvac";
import DoorLock from "./DoorLock";
import Punch from "./Punch";
import Binary from "./Binary";
import RGBWDimmer from "./RGBWDimmer";
import TunableWhite from "./TunableWhite";
import { getActions, className } from "../../../../utils/accessory-utils";

export default {
  name: "ActionEditForm",

  components: {
    switchComponent: Switch,
    punchComponent: Punch,
    binaryComponent: Binary,
    dimmerComponent: Dimmer,
    shutterComponent: Shutter,
    hvacComponent: Hvac,
    doorlockComponent: DoorLock,
    advHvacComponent: AdvHvac,
    audioComponent: Audio,
    rgbwComponent: RGBWDimmer,
    tunableWhiteComponent: TunableWhite
  },

  data: function() {
    return {
      selectedAccessories: [],
      actionList: [],
    };
  },

  computed: {
    accessories: function() {
      return this.selectedAccessories.map((accessoryId) => {
        return this.$store.getters.accessories.find(
          (accessory) => accessory.id == accessoryId
        );
      });
    },
  },

  methods: {
    getActions,
    className,

    nextStep() {
      let sceneData = { ...this.$store.getters.sceneFormData };

      let actionGroup = { ...sceneData.actionGroup };

      console.log("Old actionGroup", actionGroup);

      actionGroup.actionList = [];

      this.actionList.forEach((sceneAction) => {
        if (sceneAction) {
          actionGroup.actionList.push(...sceneAction.actions);
        }
      });

      //actionGroup.actionList = this.actionList

      console.log("New actionGroup", actionGroup);

      if (sceneData.actionGroupEditMode) {
        sceneData.scene.actionSet = sceneData.scene.actionSet.map((aGroup) => {
          return aGroup.delay == actionGroup.prevDelay ? actionGroup : aGroup;
        });
      } else {
        sceneData.scene.actionSet.push(actionGroup);
      }

      console.log("Scene Data", sceneData.scene);

      this.$store.commit("setSceneData", sceneData.scene);

      this.$store.commit("setActionGroupData", { delay: 0 });
      this.$store.commit("setSelectedAccessories", []);
      this.$store.commit("setActionGroupEditMode", false);

      this.$emit("nextStep");
    },

    getAccessory(accessoryId) {
      return this.$store.getters.accessories.find(
        (accessory) => accessory.id == accessoryId
      );
    },

    getActionData(accessoryId) {
      let action = this.actionList.find(
        (action) => action.accessory.id == accessoryId
      );
      console.log("getAccessory", action);

      return { ...action }; //{accessory: action.accessory.properties, actions: []}
    },

    onAction: function(sceneAction) {
      console.log("SceneAction", sceneAction);

      this.actionList = this.actionList.filter(
        (action) => action.accessory.id != sceneAction.accessory.id
      );
      this.actionList.push({ ...sceneAction });

      console.log("action List", this.actionList);
    },
  },

  activated() {
    this.actionList = [];

    let formData = this.$store.getters.sceneFormData;

    let actionGroup = { ...formData.actionGroup };
    this.selectedAccessories = [...formData.selectedAccessories];

    if (formData.actionGroupEditMode) {
      this.selectedAccessories.forEach((accessoryId) => {
        let accessory = {
          ...this.$store.getters.accessories.find(
            (accessory) => accessory.id == accessoryId
          ),
        };
        let actions = [];

        if (actionGroup.actionList && actionGroup.actionList.length > 0) {
          actions = actionGroup.actionList.filter(
            (action) => action.accessory.properties.id === accessoryId
          );
        }

        this.actionList.push({
          accessory: { ...accessory },
          actions: [...actions],
        });
      });
    } else {
      this.selectedAccessories.forEach((accessoryId) => {
        let accessory = {
          ...this.$store.getters.accessories.find(
            (accessory) => accessory.id == accessoryId
          ),
        };
        this.actionList.push({ accessory: { ...accessory }, actions: [] });
      });
    }

    /*if (actionGroup.actionList && actionGroup.actionList.length > 0) {
        this.actionList.push(...actionGroup.actionList)
      }

      this.selectedAccessories.forEach(accessoryId => {
        let accessory = {...this.$store.getters.accessories.find(accessory => accessory.id == accessoryId)}

        let current = this.actionList.find(action => action.accessory.properties.id == accessoryId)
        if (accessory && !current) {
          this.actionList.push(...getActions(accessory))
        }
      })*/

    console.log("Action Edit activated", this.actionList);
  },

  deactivated() {
    console.log("Action Edit deactivated");
  },
};
</script>

<style scoped>
</style>
