<template>
  <div style="max-height: 230px; overflow: scroll">
    <Container @drop="onDropAction" drag-handle-selector=".column-drag-handle">
      <Draggable v-for="(action, index) in actionList" :key="index">
        <div class="draggable-item">
          <el-col :span="3">
            <span class="column-drag-handle" style="padding:10px;">&#x2630;</span>
          </el-col>

          <el-col :span="18">
            {{getDescription(action)}}
          </el-col>

          <el-col :span="3">
            <span @click="removeAction(action)"><i class="far fa-trash-alt"></i></span>
          </el-col>

        </div>
      </Draggable>
    </Container>
  </div>
</template>

<script>
import { Container, Draggable } from "vue-smooth-dnd";

export default {
  name: "ActionList",

  components: {
    Container,
    Draggable,
  },

  props: {
    actions: {
      type: Array,
      default: function() {
        return [];
      },
    },
  },

  data: function() {
    return {
      actionList: [],

      descriptions: {
        setOn: "Set On",
        setThermoMode: "Set Thermostat Mode",
        setDeviceLevel: "Set Level",
        setSlatLevel: "Set Slat Level",
        setWindowCoveringAction: "Set Shutter Action",
        setTemperature: "Set Target Point",
        setTargetPoint: "Set Target Point",
        modeOpp: "Set Operation Mode",
        modeControl: "Set Control Mode",
        modeFan: "Set Fan Mode",
        modeUpDown: "Set Up-Down Mode",
        modeLeftRight: "Set Left-Right Mode",
        punch: "Punch",
        actionOn: "Set Status On",
        actionOff: "Set Status Off",
        setTunableColorTemperature: "Set Tunable Color Temperature",
        setTunableBrightness: "Set Tunable Brightness",
      },
    };
  },

  methods: {
    getDescription: function(action) {
      console.log("getDescription", action);

      var description = this.descriptions[action.service];
      if (!description) {
        description = action.service;
      }

      if (action.argumentList) {
        const title = action.argumentList.valueTitle;

        if (title) {
          description += " : " + title;
        } else {
          const value = action.argumentList.newTargetValue;
          if (value != null && value != undefined) {
            console.log("getDescription3", value);
            description += " : " + value;
          }
        }
      }

      return description;
    },

    removeAction: function(action) {
      if (action.service === "runPlaybackAction") {
        this.actionList = this.actionList.filter(
          (act) =>
            act.argumentList.targetMode !== action.argumentList.targetMode
        );
      } else {
        this.actionList = this.actionList.filter(
          (act) => act.service !== action.service
        );
      }

      this.$emit("onChange", [...this.actionList]);
    },

    onDropAction(dropResult) {
      console.log("dropResult", dropResult);
      this.actionList = this.applyDrag(this.actionList, dropResult);
      this.$emit("onChange", [...this.actionList]);
    },

    applyDrag(arr, dragResult) {
      console.log("applyDrag", arr, dragResult);

      const { removedIndex, addedIndex, payload } = dragResult;
      if (removedIndex === null && addedIndex === null) return arr;

      const result = [...arr];
      let itemToAdd = payload;

      if (removedIndex !== null) {
        itemToAdd = result.splice(removedIndex, 1)[0];
      }

      if (addedIndex !== null) {
        result.splice(addedIndex, 0, itemToAdd);
      }

      return result;
    },
  },

  created() {
    this.actionList = [...this.actions];
  },
};
</script>

<style scoped>
.draggable-item {
  height: 50px;
  line-height: 50px;
  text-align: left;
  width: 100%;
  display: block;
  background-color: #fff;
  outline: 0;
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
  margin-bottom: 2px;
  margin-top: 2px;
  cursor: default;
  user-select: none;
}

.column-drag-handle {
  cursor: move;
  padding: 5px;
}

span i {
  padding: 5px;
  cursor: pointer;
}

span i:hover {
  background-color: #00BFD7;
  border-radius: 5px;
  color: white;
}

span i:active {
  box-shadow: 0px 0px 10px #00BFD7;
  border-radius: 5px;
}
</style>
