<template>
  <div class="automation">
    <el-row style="margin-left: 20px; margin-right: 20px">
      <el-col :span="12">
        <el-button  type="text" @click.native.prevent="$emit('prevStep')" icon="el-icon-d-arrow-left">{{ $t('scene.back') }}</el-button>
      </el-col>

      <el-col :span="12">
        <el-button style="float: right" type="text" @click.native.prevent="nextStep">{{ $t('scene.next') }}
          <i class="el-icon-d-arrow-right"></i>
        </el-button>
      </el-col>

    </el-row>

    <el-form :model="actionGroup" class="form-container-actionform" label-position="left">
      <el-form-item :label="$t('scene.delayed-action')" label-width="150px">
        <el-switch v-model="delayed"></el-switch>
      </el-form-item>
      <el-form-item v-show="delayed">
        <el-row>
          <el-col :span="6">{{ $t('scene.hour') }}</el-col>
          <el-col :span="6">{{ $t('scene.minute') }}</el-col>
          <el-col :span="6">{{ $t('scene.second') }}</el-col>
        </el-row>
        <el-row>
          <el-col :span="6">
            <el-input-number v-model="delayTime.hour" :min="0" :max="24"></el-input-number>
          </el-col>
          <el-col :span="6">
            <el-input-number v-model="delayTime.minute" :min="0" :max="60"></el-input-number>
          </el-col>
          <el-col :span="6">
            <el-input-number v-model="delayTime.second" :min="0" :max="60"></el-input-number>
          </el-col>
        </el-row>

      </el-form-item>

      <el-table ref="accessoryTable" :data="accessories" @selection-change="handleSelectionChange">

        <el-table-column type="selection" width="55">
        </el-table-column>

        <el-table-column prop="name" :label="$t('scene.accessory')"></el-table-column>

        <el-table-column
                :label="$t('scene.room')"
                prop="roomId"
                :filters="roomList"
                :filter-method="filterRoom">
          <template slot-scope="scope">
            {{ roomName(scope.row.roomId) }}
          </template>
        </el-table-column>

        <el-table-column
                :label="$t('scene.category')"
                prop="category"
                :filters="categoryList"
                :filter-method="filterCategory">
          <template slot-scope="scope">{{ $t(categoryName(scope.row.category)) }}</template>
        </el-table-column>

      </el-table>

    </el-form>



  </div>
</template>

<script>
  import {categoryName, categoryNames, ACCESSORY_GROUP_SENSOR, ACCESSORY_GROUP_STATUS_DISPLAY} from "../../../../utils/accessory-utils";

  export default {
    name: "NewActionForm",

    data() {
      return {
        actionGroup: {
          delay: 0
        },

        selectedAccessories: [],

        delayTime: {
          hour: 0,
          minute: 0,
          second: 0
        },
        delayed: false,
        categoryNames
      }
    },

    computed: {
      categoryList: function () {
        return this.categoryNames.map(category => {
          return { text: this.$t(category.title), value: category.value }
        })
      },
      roomList: function () {
        return this.$store.getters.rooms.map(room => {
          return { text: room.name, value: room.id }
        })
      },
      accessories: function () {
        return this.$store.getters.accessories.filter(accessory => {
          return accessory.group != ACCESSORY_GROUP_SENSOR && accessory.group != ACCESSORY_GROUP_STATUS_DISPLAY
        })
      }
    },

    methods: {
      categoryName,

      filterCategory(value, row){
        return row.category == value
      },

      filterRoom(value, row){
        return row.roomId == value
      },

      roomName(roomId) {
        const room = this.$store.getters.rooms.find(room => room.id == roomId)
        return room ? room.name : roomId
      },

      nextStep() {
        if (this.selectedAccessories.length == 0) {
          this.$message({ type: "error", message: this.$t('scene.choose-accessory')})
          return
        }

        let sceneData = {...this.$store.getters.sceneFormData}

        this.actionGroup.prevDelay = this.actionGroup.delay

        let delay = this.delayTime.hour * 60 * 60 + this.delayTime.minute * 60 + this.delayTime.second

        let actionGroup = sceneData.scene.actionSet.find(actionGroup => actionGroup.delay == delay)
        if (actionGroup && !sceneData.actionGroupEditMode) {
          this.$message({
            type: "error",
            message: this.$t('scene.action-already-exists')
          })
        } else {
          this.actionGroup.delay = this.delayed ? delay : 0

          if (this.actionGroup.actionList) {
            this.actionGroup.actionList = this.actionGroup.actionList.filter(action => this.selectedAccessories.includes(action.accessory.properties.id))
          }

          this.$store.commit("setActionGroupData", this.actionGroup)
          this.$store.commit("setSelectedAccessories", this.selectedAccessories)

          this.$emit('nextStep')
        }
      },

      handleSelectionChange(val){
        this.selectedAccessories = val.map(acc => acc.id)
      }
    },

    created(){

    },

    mounted(){

      console.log("new Action mounted")


    },

    activated(){
      console.log("new Action activated")

      let sceneData = {...this.$store.getters.sceneFormData}

      this.actionGroup = sceneData.actionGroup
      this.selectedAccessories = sceneData.selectedAccessories

      console.log(this.selectedAccessories)

      this.delayed = this.actionGroup.delay > 0

      this.delayTime.second = this.actionGroup.delay % 60
      this.delayTime.minute = ((this.actionGroup.delay - this.delayTime.second) % (60 * 60)) / 60
      this.delayTime.hour = parseInt(this.actionGroup.delay / (60 * 60))

      if (this.selectedAccessories.length == 0)
        this.$refs.accessoryTable.clearSelection()

      this.selectedAccessories.forEach(accessoryId => {
        let selected = this.$refs.accessoryTable.data.find(accessory => accessory.id == accessoryId)
        if (selected) {
          this.$refs.accessoryTable.toggleRowSelection(selected, true)
        }
      })
    },

    deactivated(){
      console.log("new Action deactivated")
    }

  }
</script>

<style scoped>
@import '../../../../style/automation.css';
</style>