var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticStyle: { "max-height": "230px", overflow: "scroll" } },
    [
      _c(
        "Container",
        {
          attrs: { "drag-handle-selector": ".column-drag-handle" },
          on: { drop: _vm.onDropAction }
        },
        _vm._l(_vm.actionList, function(action, index) {
          return _c("Draggable", { key: index }, [
            _c(
              "div",
              { staticClass: "draggable-item" },
              [
                _c("el-col", { attrs: { span: 3 } }, [
                  _c(
                    "span",
                    {
                      staticClass: "column-drag-handle",
                      staticStyle: { padding: "10px" }
                    },
                    [_vm._v("☰")]
                  )
                ]),
                _c("el-col", { attrs: { span: 18 } }, [
                  _vm._v(
                    "\n          " +
                      _vm._s(_vm.getDescription(action)) +
                      "\n        "
                  )
                ]),
                _c("el-col", { attrs: { span: 3 } }, [
                  _c(
                    "span",
                    {
                      on: {
                        click: function($event) {
                          return _vm.removeAction(action)
                        }
                      }
                    },
                    [_c("i", { staticClass: "far fa-trash-alt" })]
                  )
                ])
              ],
              1
            )
          ])
        }),
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }