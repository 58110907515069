var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-card",
    { staticStyle: { "min-height": "345px" } },
    [
      _c("Header", {
        attrs: {
          slot: "header",
          title: _vm.accessory.name,
          subTitle: _vm.roomName,
          icon: _vm.accessory.icon,
          badge: _vm.actions.length
        },
        on: { onChangePage: _vm.onChangePage },
        slot: "header"
      }),
      _c(
        "div",
        { staticClass: "content-container" },
        [
          _vm.currentPage == 0
            ? _c("div", { staticStyle: { height: "230px" } }, [
                _c("div", { staticClass: "cover-container" }, [
                  _c("i", {
                    staticClass: "fa fa-music",
                    style: {
                      color: _vm.accessory.playing ? "#00BFD7" : "gray",
                      fontSize: 50 + "px"
                    }
                  })
                ]),
                _c(
                  "div",
                  { staticClass: "playback-container" },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "repeat-shuffle-button",
                        style: {
                          color: _vm.accessory.shuffle ? "#00BFD7" : "gray"
                        },
                        on: { click: _vm.onShuffle }
                      },
                      [_c("i", { staticClass: "fa fa-random" })]
                    ),
                    _c(
                      "el-button",
                      {
                        attrs: { type: "primary", size: "small", circle: "" },
                        on: { click: _vm.onPrevious }
                      },
                      [_c("i", { staticClass: "fa fa-backward" })]
                    ),
                    _c(
                      "el-button",
                      {
                        attrs: { type: "primary", circle: "" },
                        on: { click: _vm.onPlay }
                      },
                      [
                        _c("i", {
                          class: {
                            "fa fa-play": !_vm.accessory.playing,
                            "fa fa-pause": _vm.accessory.playing
                          }
                        })
                      ]
                    ),
                    _c(
                      "el-button",
                      {
                        attrs: { type: "primary", size: "small", circle: "" },
                        on: { click: _vm.onNext }
                      },
                      [_c("i", { staticClass: "fa fa-forward" })]
                    ),
                    _c(
                      "div",
                      {
                        staticClass: "repeat-shuffle-button",
                        style: {
                          color: _vm.accessory.repeat ? "#00BFD7" : "gray"
                        },
                        on: { click: _vm.onRepeat }
                      },
                      [_c("i", { staticClass: "fa fa-retweet" })]
                    )
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "volume-container" },
                  [
                    _c(
                      "div",
                      { staticClass: "mute-button", on: { click: _vm.onMute } },
                      [
                        _c("i", {
                          class: {
                            "fa fa-volume-off": _vm.accessory.mute,
                            "fa fa-volume-up": !_vm.accessory.mute
                          },
                          staticStyle: { "font-size": "24px", color: "gray" }
                        })
                      ]
                    ),
                    _c("el-slider", {
                      staticStyle: { width: "100%" },
                      on: { change: _vm.onChangeVolume },
                      model: {
                        value: _vm.accessory.volume,
                        callback: function($$v) {
                          _vm.$set(_vm.accessory, "volume", $$v)
                        },
                        expression: "accessory.volume"
                      }
                    })
                  ],
                  1
                )
              ])
            : _c("ActionList", {
                attrs: { actions: _vm.actions, currentPage: _vm.currentPage },
                on: { onChange: _vm.onChangeActions }
              })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }