var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-card",
    { staticStyle: { "min-height": "345px" } },
    [
      _c("Header", {
        attrs: {
          slot: "header",
          title: _vm.accessory.name,
          subTitle: _vm.roomName,
          icon: _vm.accessory.icon,
          badge: _vm.actions.length
        },
        on: { onChangePage: _vm.onChangePage },
        slot: "header"
      }),
      _c(
        "div",
        { staticClass: "content-container" },
        [
          _vm.currentPage == 0
            ? _c(
                "div",
                { staticClass: "progress", on: { click: _vm.onSwitch } },
                [
                  _c(
                    "el-progress",
                    {
                      attrs: {
                        type: "circle",
                        percentage: _vm.accessory.powerOn ? 100 : 0,
                        status: "text"
                      }
                    },
                    [
                      _vm._v(
                        _vm._s(
                          _vm.accessory.powerOn
                            ? _vm.$t("scene.lock")
                            : _vm.$t("scene.unlock")
                        )
                      )
                    ]
                  )
                ],
                1
              )
            : _c("ActionList", {
                attrs: { actions: _vm.actions, currentPage: _vm.currentPage },
                on: { onChange: _vm.onChangeActions }
              })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }